body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe {
  display: block;       /* iframes are inline by default */
  border: none;         /* Reset default border */
  height: 100vh;        /* Viewport-relative units */
  width: 100vw;
}

footer {
  text-align: left;
  background-color: #f1f1f1;
  color: #333;
  padding: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  border: 1px solid #f1f1f1;
  /* position: fixed;
  z-index: 1; */
}

footer a{
  color: #37aaf3;
  font-weight: 500;
}

footer p{
  margin-left: 30px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 14px;
}


footer span{
  position: absolute;
  right: 30px;
  bottom: 1px;
}